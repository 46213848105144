var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "providers-wrapper"
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, _vm._l(_vm.filteredProviders, function (provider, id) {
    var _provider$nodes$lengt, _provider$nodes, _provider$assetsCount, _provider$currentMani;

    return _c('b-col', {
      key: id,
      staticClass: "widget-col",
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "widget-wrapper",
      on: {
        "click": function click($event) {
          return _vm.$router.push('/app/data-services/' + id);
        }
      }
    }, [_c('Widget', {
      staticClass: "mb-0 provider-card"
    }, [_c('div', {
      staticClass: "provider-details"
    }, [_c('div', {
      staticClass: "provider-logo"
    }, [provider.logo ? _c('img', {
      attrs: {
        "src": provider.logo
      }
    }) : _vm._e()]), _c('div', [_c('h6', {
      staticClass: "provider-name"
    }, [_vm._v(" " + _vm._s(provider.name) + " ")]), _c('span', {
      staticClass: "provider-id"
    }, [_vm._v("id: " + _vm._s(id))])]), _c('div', {
      staticClass: "provider-description"
    }, [_vm._v(" " + _vm._s(provider.description) + " ")]), _c('div', {
      staticClass: "provider-nodes"
    }, [_c('div', [_c('label', [_vm._v(" Nodes ")])]), _c('div', [_vm._v(" " + _vm._s((_provider$nodes$lengt = provider === null || provider === void 0 ? void 0 : (_provider$nodes = provider.nodes) === null || _provider$nodes === void 0 ? void 0 : _provider$nodes.length) !== null && _provider$nodes$lengt !== void 0 ? _provider$nodes$lengt : 0) + " ")])]), _c('div', {
      staticClass: "provider-assets"
    }, [_c('div', [_c('label', [_vm._v(" Assets ")])]), _c('div', [_vm._v(" " + _vm._s((_provider$assetsCount = provider === null || provider === void 0 ? void 0 : provider.assetsCount) !== null && _provider$assetsCount !== void 0 ? _provider$assetsCount : 0) + " ")])]), _c('div', {
      staticClass: "provider-interval"
    }, [_c('div', [_c('label', [_vm._v(" Interval ")])]), _c('div', [_vm._v(" " + _vm._s(provider !== null && provider !== void 0 && (_provider$currentMani = provider.currentManifest) !== null && _provider$currentMani !== void 0 && _provider$currentMani.interval ? _vm.formatInterval(provider.currentManifest.interval) : '-') + " ")])])])])], 1)]);
  }), 1), _vm.providers == null ? _c('b-row', {
    staticClass: "justify-content-center"
  }, _vm._l(4, function (n) {
    return _c('b-col', {
      key: n,
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "preloader provider-card-preloader"
    })]);
  }), 1) : _vm._e(), _vm.filteredProviders && Object.keys(_vm.filteredProviders) == 0 ? _c('b-row', {
    staticClass: "justify-content-center"
  }, [_vm._v(" No results matching criteria ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
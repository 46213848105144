var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chart-wrapper"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "token-price-wrapper d-flex flex-column flex-md-row"
  }, [_c('div', {
    staticClass: "mb-2 mb-md-0 mr-2 d-flex align-items-center"
  }, [_vm.tokenDetails.logoURI ? _c('img', {
    staticClass: "token-logo mr-3",
    attrs: {
      "src": _vm.tokenDetails.logoURI
    }
  }) : _vm._e(), !_vm.isCurrencyToken(_vm.tokenDetails) ? _c('div', {
    staticClass: "d-inline-block token-name"
  }, [_vm._v(_vm._s(_vm.tokenDetails.name) + ": ")]) : _c('div', {
    staticClass: "d-inline-block token-name"
  }, [_vm._v(_vm._s(_vm.tokenDetails.name) + " (" + _vm._s(_vm.tokenDetails.symbol) + "): ")])]), _c('div', {
    staticClass: "mb-2 mb-md-0"
  }, [!_vm.isCurrencyToken(_vm.tokenDetails) ? _c('div', {
    staticClass: "current-price"
  }, [_vm._v(" " + _vm._s(_vm.currentPriceValue) + " ")]) : _c('div', {
    staticClass: "current-price"
  }, [_vm._v(" " + _vm._s(_vm._f("price")(_vm.currentPriceValue, {
    currency: _vm.getCurrency(_vm.tokenDetails),
    decimals: _vm.priceDecimals()
  })) + " ")]), !_vm.isCurrencyToken(_vm.tokenDetails) ? _c('div', {
    staticClass: "percentage ml-3 d-inline-block"
  }, [_vm.priceChange() && _vm.priceRelativeChange() ? _c('div', {
    class: [_vm.priceChange() >= 0 ? 'positive' : 'negative']
  }, [_c('span', [_vm._v(_vm._s(_vm.priceChange().toFixed(2)) + " ")]), _vm._v("("), _c('span', [_vm._v(_vm._s(_vm._f("percentage")(_vm.priceRelativeChange(), true)))]), _vm._v(") ")]) : _vm._e()]) : _c('div', {
    staticClass: "percentage ml-3 d-inline-block"
  }, [_vm.priceChange() && _vm.priceRelativeChange() ? _c('div', {
    class: [_vm.priceChange() >= 0 ? 'positive' : 'negative']
  }, [_c('span', [_vm._v(_vm._s(_vm._f("price")(_vm.priceChange().toFixed(_vm.priceDecimals()), {
    showPlus: true,
    currency: _vm.getCurrency(_vm.tokenDetails),
    decimals: _vm.priceDecimals()
  })) + " ")]), _vm._v("("), _c('span', [_vm._v(_vm._s(_vm._f("percentage")(_vm.priceRelativeChange(), true)))]), _vm._v(") ")]) : _vm._e()])])])])], 1), _c('hr'), _c('div', {
    staticClass: "bar-below-chart row"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-md-row justify-content-start col-12 col-lg-9"
  }, [_c('div', {
    staticClass: "time-range-links mr-3"
  }, _vm._l(_vm.timeRanges, function (range, index) {
    return _c('a', {
      key: index,
      class: {
        'selected': index === _vm.selectedTimeRangeIndex
      },
      on: {
        "click": function click($event) {
          return _vm.selectTimeRange(index);
        }
      }
    }, [_vm._v(_vm._s(range.title))]);
  }), 0), _c('div', {
    staticClass: "stats-container ml-0 mt-2 mb-2 mt-md-0 mb-md-0 ml-md-4"
  }, _vm._l(_vm.stats, function (value, title) {
    return _c('StatElem', {
      key: title,
      staticClass: "mr-2 mr-md-4",
      attrs: {
        "value": value,
        "title": title,
        "isCurrencyToken": _vm.isCurrencyToken(_vm.tokenDetails),
        "currency": _vm.getCurrency(_vm.tokenDetails),
        "decimals": _vm.priceDecimals()
      }
    });
  }), 1)]), _c('div', {
    staticClass: "last-updated-note col-lg-3 align-self-center"
  }, [_vm._v(" Last updated "), _c('strong', [_vm._v(" " + _vm._s(_vm.lastUpdatedTime) + " ")])])]), _c('hr'), _c('b-row', [_vm.isCurrencyToken(_vm.tokenDetails) ? _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "9"
    }
  }, [_c('div', {
    staticClass: "price-chart-container"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c('vue-loaders-ball-beat', {
    attrs: {
      "color": "var(--redstone-red-color)",
      "scale": "1"
    }
  })], 1), _c('TokenPriceChart', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading,
      expression: "!loading"
    }],
    attrs: {
      "data": _vm.chartData,
      "symbol": _vm.tokenDetails.symbol
    }
  })], 1)]) : _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "price-chart-container"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c('vue-loaders-ball-beat', {
    attrs: {
      "color": "var(--redstone-red-color)",
      "scale": "1"
    }
  })], 1), _c('TokenPriceChart', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading,
      expression: "!loading"
    }],
    attrs: {
      "data": _vm.chartData,
      "symbol": _vm.tokenDetails.symbol
    }
  })], 1)]), _vm.isCurrencyAndNotRedstoneProvider(_vm.tokenDetails) ? _c('b-col', {
    staticClass: "mt-5 mt-md-0",
    attrs: {
      "xs": "12",
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "data-sources"
  }, [_vm._v(" Caution ")]), _c('div', [_vm._v(" We do not keep data for this token. If you would like us to start collecting it please contact us at "), _c('a', {
    attrs: {
      "href": "mailto:hello@redstone.finance"
    }
  }, [_vm._v("hello@redstone.finance")])])]) : _vm.isCurrencyToken(_vm.tokenDetails) ? _c('b-col', {
    staticClass: "mt-5 mt-md-0",
    attrs: {
      "xs": "12",
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "data-sources"
  }, [_vm._v(" Data sources (" + _vm._s(_vm.sourcesCount) + ") ")]), _c('b-form-group', {
    staticClass: "data-sources-container",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          attrs: {
            "id": "checkbox-group-2",
            "aria-describedby": ariaDescribedby,
            "name": "flavour-2"
          },
          model: {
            value: _vm.selectedSources,
            callback: function callback($$v) {
              _vm.selectedSources = $$v;
            },
            expression: "selectedSources"
          }
        }, _vm._l(_vm.sources, function (source) {
          return _c('b-form-checkbox', {
            key: source,
            staticClass: "source-checkbox",
            style: {
              color: _vm.sourceColors[source]
            },
            attrs: {
              "value": source
            }
          }, [_c('div', {
            staticClass: "source-label"
          }, [_c('div', {
            staticClass: "source-name"
          }, [_c('img', {
            staticClass: "source-logo",
            attrs: {
              "src": _vm.getImageForSource(source),
              "alt": source,
              "title": source,
              "loading": "lazy"
            }
          }), source == 'aggregated' ? _c('span', [_vm._v("Median")]) : _c('span', [_vm._v(_vm._s(source))])]), _c('div', {
            staticClass: "source-value"
          }, [_vm._v(" " + _vm._s(_vm._f("price")(_vm.getCurrentPriceForSource(source), {
            eNotationForSmallValues: true,
            currency: _vm.getCurrency(_vm.tokenDetails),
            decimals: _vm.priceDecimals()
          })) + " ")])])]);
        }), 1)];
      }
    }])
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', {
    staticClass: "justify-content-center"
  }, _vm._l(_vm.nodes, function (node, index) {
    return _c('b-col', {
      key: index,
      staticClass: "widget-col",
      attrs: {
        "cols": "12"
      }
    }, [_c('a', {
      staticClass: "widget-wrapper node-wrapper",
      attrs: {
        "href": node.url,
        "target": "_blank"
      }
    }, [_c('Widget', {
      staticClass: "node-card"
    }, [_c('div', {
      staticClass: "node-details"
    }, [_c('div', {
      staticClass: "node-logo"
    }, [node.logo ? _c('img', {
      attrs: {
        "src": node.logo
      }
    }) : _vm._e()]), _c('h6', {
      staticClass: "node-name"
    }, [_vm._v(" " + _vm._s(node.name) + " ")]), _c('div', {
      staticClass: "node-description"
    }, [_vm._v(" " + _vm._s(node.description) + " ")]), _c('div', {
      staticClass: "node-column"
    }, [_c('div', [_c('label', [_vm._v("EVM address")])]), _c('div', {
      on: {
        "click": function click(event) {
          return _vm.copyToClipboard(event, node.evmAddress);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.shortenEvmAddress(node.evmAddress)) + " "), _c('i', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "fa fa-copy copy-icon",
      attrs: {
        "title": "Copy to clipboard"
      }
    })])])])])], 1)]);
  }), 1), !_vm.nodes ? _c('b-row', {
    staticClass: "justify-content-center"
  }, _vm._l(4, function (n) {
    return _c('b-col', {
      key: n,
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "preloader node-card-preloader"
    })]);
  }), 1) : _vm._e(), _vm.nodes && _vm.nodes.length == 0 ? _c('b-row', {
    staticClass: "justify-content-center"
  }, [_vm._v(" No results matching criteria ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: 'label-value ' + (_vm.alignRight ? 'align-right' : '')
  }, [_c('div', [_c('label', {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])]), _vm.value !== undefined ? _c('div', {
    class: 'value'
  }, [_vm._v(" " + _vm._s(_vm.value ? _vm.value : '-') + " ")]) : _c('vue-loaders-ball-beat', {
    attrs: {
      "color": "var(--redstone-red-color)",
      "scale": "0.5"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
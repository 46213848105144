var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    staticClass: "border-0"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', [_vm._v(" Fetch the token price with just one line of code using "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://github.com/redstone-finance/redstone-api"
    }
  }, [_vm._v(" RedStone API ")])]), _c('div', [_c('b-button', {
    staticClass: "btn-danger btn-docs rounded-pill",
    attrs: {
      "target": "_blank",
      "href": "https://api.docs.redstone.finance/",
      "variant": "primary"
    }
  }, [_vm._v("Check documentation")])], 1)]), _c('hr'), _vm.shouldLoadCodeBox ? _c('iframe', {
    staticStyle: {
      "width": "100%",
      "height": "500px",
      "border": "0",
      "border-radius": "4px",
      "overflow": "hidden"
    },
    attrs: {
      "src": "https://codesandbox.io/embed/limestone-api-demo-0h4oo?fontsize=14&hidenavigation=1&theme=dark",
      "title": "limestone-api-demo",
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }
  }) : _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" CodeBox displaying is disabled in dev environment. It made a mess in console. ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
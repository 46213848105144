import { render, staticRenderFns } from "./StatElem.vue?vue&type=template&id=340c7dc1&scoped=true&"
import script from "./StatElem.vue?vue&type=script&lang=js&"
export * from "./StatElem.vue?vue&type=script&lang=js&"
import style0 from "./StatElem.vue?vue&type=style&index=0&id=340c7dc1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "340c7dc1",
  null
  
)

export default component.exports
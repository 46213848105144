var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.childrenLinks && _vm.isHeader ? _c('li', {
    class: {
      headerLink: true,
      className: _vm.className
    }
  }, [!_vm.link.includes('http') ? _c('router-link', {
    staticClass: "sidebar-link",
    attrs: {
      "to": _vm.link
    }
  }, [_c('span', {
    staticClass: "icon"
  }, [_vm.iconName ? _c('i', {
    class: _vm.fullIconName
  }) : _vm._e(), _vm.imgUrl ? _c('img', {
    attrs: {
      "src": _vm.imgUrl
    }
  }) : _vm._e()]), _vm.showHeader ? _c('span', [_vm._v(_vm._s(_vm.header))]) : _vm._e(), _vm.label ? _c('sup', {
    staticClass: "headerLabel",
    class: 'text-' + _vm.labelColor
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _vm.badge ? _c('b-badge', {
    attrs: {
      "variant": "primary",
      "pill": ""
    }
  }, [_vm._v(_vm._s(_vm.badge))]) : _vm._e()], 1) : _vm._e(), _vm.link.includes('http') ? _c('a', {
    staticClass: "sidebar-link",
    attrs: {
      "href": _vm.link,
      "target": "_blank"
    }
  }, [_c('span', {
    staticClass: "icon"
  }, [_vm.iconName ? _c('i', {
    class: _vm.fullIconName
  }) : _vm._e(), _vm.imgUrl ? _c('img', {
    attrs: {
      "src": _vm.imgUrl
    }
  }) : _vm._e()]), _vm.showHeader ? _c('span', [_vm._v(_vm._s(_vm.header))]) : _vm._e(), _vm.label ? _c('sup', {
    staticClass: "headerLabel",
    class: 'text-' + _vm.labelColor
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _vm.badge ? _c('b-badge', {
    attrs: {
      "variant": "primary",
      "pill": ""
    }
  }, [_vm._v(_vm._s(_vm.badge))]) : _vm._e()], 1) : _vm._e()], 1) : _vm.childrenLinks ? _c('li', {
    class: {
      headerLink: true,
      className: _vm.className
    }
  }, [_c('div', {
    on: {
      "click": function click() {
        return _vm.togglePanelCollapse(_vm.link);
      }
    }
  }, [_c('router-link', {
    staticClass: "d-flex sidebar-link",
    attrs: {
      "to": _vm.link,
      "event": ""
    }
  }, [_c('span', {
    staticClass: "icon"
  }, [_vm.iconName ? _c('i', {
    class: _vm.fullIconName
  }) : _vm._e(), _vm.imgUrl ? _c('img', {
    attrs: {
      "src": _vm.imgUrl
    }
  }) : _vm._e()]), _vm.showHeader ? _c('span', [_vm._v(_vm._s(_vm.header))]) : _vm._e(), _vm.label ? _c('sup', {
    staticClass: "ml-1 headerLabel",
    class: 'text-' + _vm.labelColor
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c('div', {
    class: {
      caretWrapper: true,
      carretActive: _vm.isActive
    }
  }, [_c('i', {
    staticClass: "fa fa-angle-right"
  })])])], 1), _c('b-collapse', {
    attrs: {
      "id": 'collapse' + _vm.index,
      "visible": _vm.isActive
    }
  }, [_c('ul', {
    staticClass: "sub-menu"
  }, _vm._l(_vm.childrenLinks, function (link) {
    return _c('NavLink', {
      key: link.link,
      attrs: {
        "activeItem": _vm.activeItem,
        "header": link.header,
        "index": link.index,
        "link": link.link,
        "childrenLinks": link.childrenLinks
      }
    });
  }), 1)])], 1) : _c('li', [_c('router-link', {
    attrs: {
      "to": _vm.index !== 'menu' && _vm.link
    }
  }, [_vm._v(" " + _vm._s(_vm.header) + " "), _vm.label ? _c('sup', {
    staticClass: "headerLabel",
    class: 'text-' + _vm.labelColor
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
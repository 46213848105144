var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    ref: "widget",
    class: {
      widget: true,
      className: _vm.className,
      collapsed: _vm.state === 'collapse',
      fullscreened: _vm.state === 'fullscreen',
      loading: _vm.fetchingData
    }
  }, [_vm.title && typeof _vm.title === 'string' && !_vm.customHeader ? _c('h5', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm.title && _vm.customHeader ? _c('header', {
    staticClass: "title",
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  }) : _vm._e(), !_vm.customControls && _vm.mainControls ? _c('div', {
    staticClass: "widgetControls widget-controls"
  }, [_vm.settings || _vm.settingsInverse ? _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "la la-cog"
  })]) : _vm._e(), _vm.refresh ? _c('a', {
    attrs: {
      "href": "#",
      "id": "reloadId-".concat(_vm.randomId)
    },
    on: {
      "click": function click($event) {
        return _vm.loadWidgster($event);
      }
    }
  }, [typeof _vm.refresh === 'string' ? _c('strong', {
    staticClass: "text-gray-light"
  }, [_vm._v(_vm._s(_vm.refresh))]) : _c('i', {
    staticClass: "la la-refresh"
  }), _vm.showTooltip ? _c('b-tooltip', {
    attrs: {
      "placement": _vm.tooltipPlacement,
      "target": "reloadId-".concat(_vm.randomId)
    }
  }, [_vm._v("Reload ")]) : _vm._e()], 1) : _vm._e(), _vm.fullscreen && _vm.state !== 'fullscreen' ? _c('a', {
    attrs: {
      "href": "#",
      "id": "fullscreenId-".concat(_vm.randomId)
    },
    on: {
      "click": function click($event) {
        return _vm.changeState($event, 'fullscreen');
      }
    }
  }, [_c('i', {
    staticClass: "glyphicon glyphicon-resize-full"
  }), _vm.showTooltip ? _c('b-tooltip', {
    attrs: {
      "placement": _vm.tooltipPlacement,
      "target": "fullscreenId-".concat(_vm.randomId)
    }
  }, [_vm._v("Fullscreen ")]) : _vm._e()], 1) : _vm._e(), _vm.fullscreen && _vm.state === 'fullscreen' ? _c('a', {
    attrs: {
      "href": "#",
      "id": "restoreId-".concat(_vm.randomId)
    },
    on: {
      "click": function click($event) {
        return _vm.changeState($event, 'default');
      }
    }
  }, [_c('i', {
    staticClass: "glyphicon glyphicon-resize-small"
  }), _vm.showTooltip ? _c('b-tooltip', {
    attrs: {
      "placement": _vm.tooltipPlacement,
      "target": "restoreId-".concat(_vm.randomId)
    }
  }, [_vm._v("Restore ")]) : _vm._e()], 1) : _vm._e(), _vm.collapse && _vm.state !== 'collapse' ? _c('span', [_c('a', {
    attrs: {
      "href": "#",
      "id": "collapseId-".concat(_vm.randomId)
    },
    on: {
      "click": function click($event) {
        return _vm.changeState($event, 'collapse');
      }
    }
  }, [_c('i', {
    staticClass: "la la-angle-down"
  }), _vm.showTooltip ? _c('b-tooltip', {
    attrs: {
      "placement": _vm.tooltipPlacement,
      "target": "collapseId-".concat(_vm.randomId)
    }
  }, [_vm._v("Collapse ")]) : _vm._e()], 1)]) : _vm._e(), _vm.collapse && _vm.state === 'collapse' ? _c('span', [_c('a', {
    attrs: {
      "href": "#",
      "id": "expandId-".concat(_vm.randomId)
    },
    on: {
      "click": function click($event) {
        return _vm.changeState($event, 'default');
      }
    }
  }, [_c('i', {
    staticClass: "la la-angle-up"
  }), _vm.showTooltip ? _c('b-tooltip', {
    attrs: {
      "placement": _vm.tooltipPlacement,
      "target": "expandId-".concat(_vm.randomId)
    }
  }, [_vm._v("Expand ")]) : _vm._e()], 1)]) : _vm._e(), _vm.close ? _c('a', {
    attrs: {
      "href": "#",
      "id": "closeId-".concat(_vm.randomId)
    },
    on: {
      "click": function click($event) {
        return _vm.closeWidget($event);
      }
    }
  }, [typeof _vm.refresh === 'string' ? _c('strong', {
    staticClass: "text-gray-light"
  }, [_vm._v(_vm._s(_vm.close))]) : _c('i', {
    staticClass: "la la-remove"
  }), _vm.showTooltip ? _c('b-tooltip', {
    attrs: {
      "placement": _vm.tooltipPlacement,
      "target": "closeId-".concat(_vm.randomId)
    }
  }, [_vm._v("Close ")]) : _vm._e()], 1) : _vm._e()]) : _vm._e(), _vm.customControls ? _c('div', {
    ref: "customControlsRef",
    staticClass: "widgetControls widget-controls",
    domProps: {
      "innerHTML": _vm._s(_vm.customControls)
    }
  }) : _vm._e(), _c('div', {
    ref: "widgetBodyRef",
    class: "widgetBody widget-body ".concat(_vm.bodyClass),
    style: {
      display: _vm.state === 'collapse' ? 'none' : ''
    }
  }, [_vm.fetchingData && _vm.showLoader ? _c('Loader', {
    class: 'widget-loader',
    attrs: {
      "size": 40
    }
  }) : _vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
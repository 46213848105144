var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-navbar', {
    staticClass: "header d-print-none app-header"
  }, [_c('b-nav', [_c('b-nav-item', [_c('a', {
    staticClass: "fs-lg d-md-none",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.toggleSidebarMenu
    }
  }, [_c('i', {
    staticClass: "fi flaticon-menu"
  })])])], 1), _c('b-nav', {
    staticClass: "w-25 w-md-auto"
  }, [_c('b-form', {
    staticClass: "ml-1",
    attrs: {
      "inline": ""
    }
  }, [_c('b-form-group', {
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_vm.showSearchInputInHeader ? _c('b-input-group', {
    staticClass: "input-group-no-border",
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('b-input-group-text', [_c('i', {
          staticClass: "fi flaticon-search-2"
        })])];
      },
      proxy: true
    }], null, false, 11198452)
  }, [_c('b-form-input', {
    attrs: {
      "id": "search-input",
      "placeholder": "Search..."
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1) : _c('a', {
    attrs: {
      "href": "javascript:window.history.back()"
    }
  }, [_c('i', {
    staticClass: "fa flaticon-chevron-back"
  })])], 1)], 1)], 1), _c('b-nav', {
    staticClass: "align-items-center flex-grow-1 justify-content-end",
    attrs: {
      "id": "use-buttons"
    }
  }, [_c('a', {
    attrs: {
      "id": "use-push-button",
      "target": "_blank",
      "href": "https://docs.redstone.finance/docs/smart-contract-devs/price-feeds"
    }
  }, [_c('b-button', {
    staticClass: "btn btn-inverted rounded-pill",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Use Push Model ")])], 1), _c('a', {
    attrs: {
      "id": "use-pull-button",
      "target": "_blank",
      "href": "https://docs.redstone.finance/docs/smart-contract-devs/get-started/redstone-core"
    }
  }, [_c('b-button', {
    staticClass: "btn btn-danger rounded-pill",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Use Pull Model ")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "star-rating"
  }, _vm._l(_vm.ratings, function (rating) {
    return _c('label', {
      key: rating,
      staticClass: "star-rating__star",
      class: {
        'is-selected': _vm.value >= rating && _vm.value != null,
        'is-disabled': _vm.disabled
      },
      on: {
        "click": function click($event) {
          return _vm.set(rating);
        },
        "mouseover": function mouseover($event) {
          return _vm.starOver(rating);
        },
        "mouseout": _vm.starOut
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.value,
        expression: "value"
      }],
      staticClass: "star-rating star-rating__checkbox",
      attrs: {
        "type": "radio",
        "disabled": _vm.disabled
      },
      domProps: {
        "value": rating,
        "checked": _vm._q(_vm.value, rating)
      },
      on: {
        "change": function change($event) {
          _vm.value = rating;
        }
      }
    }), _vm._v("★ ")]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "error-page"
  }, [_c('b-container', [_c('div', {
    staticClass: "errorContainer mx-auto"
  }, [_c('h1', {
    staticClass: "errorCode"
  }, [_vm._v("404")]), _c('p', {
    staticClass: "errorInfo"
  }, [_vm._v(" Opps, it seems that this page does not exist. ")]), _c('p', {
    staticClass: "errorHelp mb-3"
  }, [_vm._v(" If you are sure it should, search for it. ")]), _c('b-form', [_c('b-form-group', [_c('b-form-input', {
    staticClass: "input-no-border",
    attrs: {
      "type": "text",
      "placeholder": "Search Pages"
    }
  })], 1), _c('router-link', {
    attrs: {
      "tag": "a",
      "to": "/app/extra/search"
    }
  }, [_c('b-button', {
    staticClass: "errorBtn",
    attrs: {
      "type": "submit",
      "variant": "success"
    }
  }, [_vm._v(" Search "), _c('i', {
    staticClass: "la la-search ml-xs"
  })])], 1)], 1)], 1), _c('footer', {
    staticClass: "pageFooter"
  }, [_vm._v(" 2019 © Sing App Vue Admin Dashboard Template. ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }